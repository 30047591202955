$red: #e1173f;
$redC: #cc092f;
$lightPurple: #6b00b4;
$purple: #4e0f99;
$darkPurple: #40178c;
$star: #e2b929;
$green: #71c879;

$black: #000;
$gray0: #000;
$gray1: #111;
$gray2: #222;
$gray3: #333;
$gray3C: #3c3c3c;
$gray4: #444;
$gray5: #555;
$gray6: #666;
$gray69: #696969;
$gray7: #777;
$gray8: #888;
$gray9: #999;
$graya: #aaa;
$grayb: #bbb;
$grayc: #ccc;
$graycd: #cdcdcd;
$grayd: #ddd;
$grayd2: #d2d2d2;
$graye: #e5e5e5;
$graye4: #e4e4e4;
$grayEA: #eaeaea;
$grayf: #f4f3f6;
$white: #fff;

/* PADRÕES */
$bdRadius: 0.25em; /* 4px */

$gradientVertical: linear-gradient(180deg, rgb(204, 9, 47) 40%, rgb(184, 21, 112) 90%);
$gradientHorizontal: linear-gradient(90deg, rgb(204, 9, 47) 40%, rgb(184, 21, 112) 90%);

$bgDgdPurple: linear-gradient(180deg, #4e0f99 0%, #3d0c77 100%);
$bgDgdPurplePink: linear-gradient(180deg, #a32f77 0%, #303a8f 100%);

/* normal */
:root {
	--bg: #fff;
	--bg-full: #f9f8f9;
	--bg-disabled: linear-gradient(180deg, #a32f77 0%, #303a8f 100%);
	--bg-contrast: #f0eff2;
	--hover-text: #69646a;
	--hover-btn: #e5e5e5;
	--icon-primary: #e1173f;
	--icon-secondary: #fff;
	--icon-tertiary: #888;
	--text-primary: #3e3e3e;
	--text-secondary: #e1173f;
	--text-tertiary: #4e0f99;
	--text-quaternary: #cc092f;
	--text-input: #999;
	--text-select: #7b7b7b;
	--contrast: #fff;
	--shadow: #cccccc;
	--star: #e2b929;
	--btn-primary: #e1173f;
	--gradientVertical: linear-gradient(180deg, rgb(204, 9, 47) 40%, rgb(184, 21, 112) 90%);
	--gradientHorizontal: linear-gradient(90deg, rgb(204, 9, 47) 40%, rgb(184, 21, 112) 90%);
	--checked: #71c879;
	--load: #de1d48;
	--loadDis: #a5a5a9;
	--select-disabled: #e5e5e5;
	--field-feedback: #fff;
	--color-close: #3e3e3e;
}

/* centurion-mode */
body.centurion-mode {
	--bg: #111;
	--bg-full: #202121;
	--bg-disabled: linear-gradient(180deg, #a32f77 0%, #303a8f 100%);
	--bg-contrast: var(--bg-full);
	--hover-text: #9da3a7;
	--hover-btn: #2e2e2e;
	--icon-primary: #e1173f;
	--icon-secondary: #fff;
	--icon-tertiary: #888;
	--text-primary: #fff;
	--text-secondary: #e1173f;
	--text-tertiary: #fbb4f8;
	--text-quaternary: #cc092f;
	--text-input: #999;
	--text-select: #7b7b7b;
	--contrast: #fff;
	--shadow: #cccccc;
	--star: #e2b929;
	--btn-primary: #e1173f;
	--gradientVertical: linear-gradient(180deg, rgb(204, 9, 47) 40%, rgb(184, 21, 112) 90%);
	--gradientHorizontal: linear-gradient(90deg, rgb(204, 9, 47) 40%, rgb(184, 21, 112) 90%);
	--checked: #71c879;
	--load: #de1d48;
	--loadDis: #a5a5a9;
	--select-disabled: #000101;
	--field-feedback: #3e3e3e;
	--color-close: #fff;
}
