@import './theme';
@import './mixin';
@import './fonts';
@import '~swiper/scss';
@import '~swiper/scss/navigation';
@import '~swiper/scss/pagination';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import './app/component/field/field';
@import './app/component/table/table';

/* RESET */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 16px;
	vertical-align: baseline;
}

body {
	-webkit-text-size-adjust: none;
	line-height: 1;
	position: relative;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

button,
input,
input:focus {
	border: none;
	background-image: none;
	background-color: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	outline: none;
}

input,
textarea,
select,
input::placeholder,
textarea::placeholder,
select::placeholder {
	font-family: 'BradescoSans';
}

button {
	transition: 0.5s;

	&:hover,
	&:active,
	&:focus {
		transition: 0.5s;
		font-family: 'BradescoSans';
	}
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

/* RESET PROGRAMA MENU */

body {
	background: #f8f8f8;
	color: $gray3C;
	font-family: 'BradescoSans';

	@include medium-only {
		margin-bottom: calc(60px + 3.05em);
	}

	&.hasOpenedRightbar {
		overflow: hidden;
	}
}

a {
	text-decoration: none;
}

/* SECTION */
section {
	margin: 0.9375em auto;
}

.page {
	min-height: 100vh;
	@include flex(initial, initial, column);

	&__body {
		flex-grow: 1;
		@include flex(initial, initial, column);
	}
}

/* WRAPPER */
.wrapper {
	box-sizing: content-box;
	margin: 0 auto;
	padding: 0 1em;
	max-width: 1180px;
}

/* BUTTONS */
.button {
	background: $gradientHorizontal;
	border: none;
	border-radius: 40px;
	color: $white;
	cursor: pointer;
	font-family: 'BradescoSans';
	transition: all 0.4s;

	&--disable {
		background: $white;
		color: $gray4;
	}

	&:hover {
		filter: brightness(1.3);
		transition: all 0.4s;
	}
}

.buttonLight {
	background: transparent;
	border: 1px solid $red;
	border-radius: 40px;
	color: $red !important;
	cursor: pointer;
	font-family: 'BradescoSans';
	transition: all 0.4s;

	&:hover {
		filter: brightness(1.3);
		transition: all 0.4s;
	}
}

.buttonWhite {
	background: $white;
	border: none;
	border-radius: 40px;
	color: $red;
	cursor: pointer;
	font-family: 'BradescoSans';
	transition: all 0.4s;

	&:hover {
		filter: brightness(1.3);
		transition: all 0.4s;
	}
}

.buttonInverse {
	background: $grayf;
	border: none;
	border-radius: 40px;
	color: $red;
	cursor: pointer;
	font-family: 'BradescoSans';
	transition: all 0.4s;

	&:hover {
		filter: brightness(1.3);
		transition: all 0.4s;
	}
}

.buttonGhost {
	background: transparent;
	border: 1px solid $white;
	border-radius: 40px;
	color: $white;
	cursor: pointer;
	font-family: 'BradescoSans';
	transition: all 0.4s;

	&:hover {
		filter: brightness(1.3);
		transition: all 0.4s;
	}
}

/* CORES & BG GRADIENTS */
.purple {
	color: $purple;
}
.red {
	color: $red;
}
//usado no headerLogin
.gradientVertical {
	background: $gradientVertical;
}
//usado no search, buttons e footer
.gradientHorizontal {
	background: var(--gradientHorizontal);
}
//usado no navBar das paginas Termos de Uso e Politica de Privacidade
.bgDgdPurple {
	background: $bgDgdPurple;
}

.none {
	display: none;
}

.hide {
	opacity: 0;
	visibility: hidden;
}

/* ORDERS DESKTOP & MOBILE */
.desktop {
	order: 999;
}

.mobile {
	order: 999;
}

.desktop__order,
.mobile__order {
	@for $i from 1 to 20 {
		&--#{$i} {
			order: $i;
		}
	}
}

/* SLIDES STYLES */
.slide001 {
	&__control {
		bottom: 1.05em;
		left: 50% !important;
		position: absolute;
		z-index: 2;

		@include small-only {
			bottom: 0.6em;
			left: 1.9em;
		}

		&__item {
			border: 1px solid $white;
			border-radius: 50%;
			cursor: pointer;
			display: inline-block;
			height: 0.4em;
			margin-right: 0.5em;
			width: 0.4em;

			@include small-only {
				margin-right: 0.4em;
			}

			&--checked {
				background-color: $white;
			}
		}
	}
}

.slide002 {
	&__control {
		bottom: 1.05em;
		left: calc(50% - 1em) !important;
		position: absolute;
		z-index: 2;

		@include small-only {
			bottom: 0.6em;
			left: 1.9em;
		}

		&__item {
			border: 1px solid $white;
			border-radius: 50%;
			cursor: pointer;
			display: inline-block;
			height: 0.4em;
			margin-right: 0.5em;
			width: 0.4em;

			@include small-only {
				margin-right: 0.4em;
			}

			&--checked {
				background-color: $white;
			}
		}
	}
}

/* DESKTOP & MOBILE ONLY*/
.desktopOnly {
	display: none;

	@include medium-up {
		display: block;
	}
}

.mobileOnly {
	display: none;

	@include medium-only {
		display: block;
	}
}

.swal2-popup {
	padding: 30px 30px !important;
	width: initial !important;
	background-color: var(--bg) !important;
	color: var(--text-primary) !important;

	.swal2-actions {
		.button {
			font-family: 'BradescoSans';
			min-height: 30px;
			padding: 5px 15px;
		}
	}

	.swal2-title {
		@include title(18px, 600);
		font-family: 'BradescoSans';
	}
}
