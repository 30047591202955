@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-Bold-web.ttf) format('truetype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-BoldItalic-web.ttf) format('truetype');
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-Condensed-web.ttf) format('truetype');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-CondensedBold-web.ttf) format('truetype');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-Italic-web.ttf) format('truetype');
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-Light-web.ttf) format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-LightItalic-web.ttf) format('truetype');
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-Medium-web.ttf) format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-MediumItalic-web.ttf) format('truetype');
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-Regular-web.ttf) format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-SemiBold-web.ttf) format('truetype');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-SemiBoldItalic-web.ttf) format('truetype');
	font-weight: 600;
	font-style: italic;
}
@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-Thin-web.ttf) format('truetype');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-ThinItalic-web.ttf) format('truetype');
	font-weight: 100;
	font-style: italic;
}
@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-XBold-web.ttf) format('truetype');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: 'BradescoSans';
	src: url(assets/fonts/BradescoSans-XBoldItalic-web.ttf) format('truetype');
	font-weight: 900;
	font-style: italic;
}
