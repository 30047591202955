/* ---------- BREAKPOINS ---------- */
/* small only - até 599px */
@mixin small-only {
	@media (max-width: 37.4375em) {
		@content;
	}
}

/* small up - a partir de 600px */
@mixin small-up {
	@media (min-width: 37.5em) {
		@content;
	}
}

/* medium only - até 899px */
@mixin medium-only {
	@media (max-width: 56.1875em) {
		@content;
	}
}

/* medium up - a partir de 900px */
@mixin medium-up {
	@media (min-width: 56.25em) {
		@content;
	}
}

/* large only - até 1199px */
@mixin large-only {
	@media (max-width: 74.9375em) {
		@content;
	}
}

/* large up - a partir de 1200px */
@mixin large-up {
	@media (min-width: 75em) {
		@content;
	}
}

@mixin horGradient {
	background-image: linear-gradient(to right, #cc092f, #b81570);
}

@mixin xs {
	@media (max-width: 576px) {
		@content;
	}
}

@mixin sm {
	@media (min-width: 576px) {
		@content;
	}
}

@mixin md {
	@media (min-width: 768px) {
		@content;
	}
}

@mixin lg {
	@media (min-width: 992px) {
		@content;
	}
}

@mixin xl {
	@media (min-width: 1200px) {
		@content;
	}
}

@mixin xxl {
	@media (min-width: 1400px) {
		@content;
	}
}

@mixin minMedia($breakpoint) {
	@media (min-width: $breakpoint) {
		@content;
	}
}

@mixin maxMedia($breakpoint) {
	@media (max-width: $breakpoint) {
		@content;
	}
}

/* ---------- FLEX ---------- */
@mixin flex($align: initial, $justify: initial, $direction: initial) {
	display: flex;
	align-items: $align;
	justify-content: $justify;
	flex-direction: $direction;
}

/* ---------- INLINE - FLEX ---------- */
@mixin inline-flex($align: initial, $justify: initial, $direction: initial) {
	display: inline-flex;
	align-items: $align;
	justify-content: $justify;
	flex-direction: $direction;
}

/* ---------- FONTS ---------- */
@mixin title($size: 16px, $weight: 500, $spacing: initial, $height: initial) {
	font-family: 'BradescoSans';
	font-size: $size;
	font-weight: $weight;
	letter-spacing: $spacing;
	line-height: $height;
}

@mixin text($size: 16px, $weight: 400, $spacing: initial, $height: initial) {
	font-family: 'BradescoSans';
	font-size: $size;
	font-weight: $weight;
	letter-spacing: $spacing;
	line-height: $height;
}

/* ---------- ROW ---------- */
@mixin row($gap: -15px) {
	display: flex;
	flex-wrap: wrap;
	margin-left: $gap;
	margin-right: $gap;
}

/* ---------- COLUMN ---------- */
@mixin column($size: 100%, $gap: 15px) {
	max-width: $size;
	flex-basis: $size;
	padding-left: $gap;
	padding-right: $gap;
	box-sizing: border-box;
}
