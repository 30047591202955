@import './../../../theme';
@import './../../../mixin';

.field {
	width: 100%;
	text-align: left;

	&__wrapper {
		min-height: 50px;
		border-radius: 4px;
		box-shadow: 0px 1px 7px adjust-color($color: $gray0, $alpha: -0.83);
		@include flex(center);
		background-color: $white;
	}

	&__icon {
		display: flex !important;
		height: 50px;
		overflow: hidden;
		position: relative;
		text-shadow: 0.5px 0.5px 0px $grayc;
		width: 50px;
		z-index: 1;
		@include flex(center, center);

		&:before {
			font-size: 26px;
			color: $gray6;
		}
		&.left {
			order: -1;
			margin-right: -50px;

			& ~ .field__input {
				padding-left: 50px;
			}
		}
		&.right {
			order: 1;
			margin-left: -50px;

			& ~ .field__input {
				padding-right: 50px;
			}
		}
	}
	&__input {
		width: 100%;
		padding: 15px;
		border: 0px;
		border-radius: 4px;
		outline: none;
		color: $gray6;
		@include text(14px, 400);

		&::placeholder {
			@include text(14px, 400);
		}

		&[readonly] {
			background-color: $graye;
			cursor: not-allowed;
		}
	}
	&__feedback {
		margin-top: 2px;
		@include title(12px);
		display: block;
		color: $red;
	}
	&__helper {
		margin-top: 5px;
		@include title(11px);
		display: block;
		color: $gray3C;
	}
}

.confirm {
	@include flex(center);
	cursor: pointer;

	&.confirmed {
		.confirm__check {
			transition: 0.5s;
			background-image: $gradientHorizontal;
		}
	}
	&__check {
		width: 30px;
		height: 30px;
		border-radius: 50px;
		margin-right: 10px;
		transition: 0.5s;
		background-image: linear-gradient(90deg, $graye 0%, $graye 100%);
		@include flex(center, center);
		cursor: pointer;

		&:before {
			content: '\f06b';
			display: inline-block;
			font: normal normal normal 18px/1 Tagme;
			color: $white;
		}
	}
	&__label {
		width: calc(100% - 40px);
		@include title(12px, 400);
	}
	&__label__strong {
		@include title(12px, 500);
	}
	&__label__link {
		@include title(12px, 500);
		text-decoration: underline;
	}
}
