.table {
	width: 100%;
	text-align: left;
	overflow-y: hidden;
	overflow-x: auto;

	&__main {
		width: 100%;
	}
	&__thead {
		border-bottom: 1px solid $grayd2;

		.table__th,
		.table__td {
			padding: 20px 0px;
		}
	}
	&__tbody {
		.table__th,
		.table__td {
			padding: 10px 0px;
		}
	}
	&__tr {
	}
	&__th {
		@include title(14px);
		color: $gray3C;
		vertical-align: middle;
	}
	&__td {
		@include title(14px, 300, initial, initial);
		color: $gray3C;
		vertical-align: middle;

		&__wrapper {
			@include flex(center);
		}
	}
	&__avatar {
		width: 50px;
		height: 50px;
		border-radius: 50px;
		margin-right: 10px;
		background-color: $grayb;
		overflow: hidden;
	}
	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&__button {
		min-height: 30px;
		padding: 5px 15px;

		& + & {
			margin-left: 10px;
		}
	}
}
